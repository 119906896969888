var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.apolice.PolicyUniqueId
    ? _c(
        "div",
        { staticClass: "content-cancelamento vx-row px-8 relative" },
        [
          _c("section", { staticClass: "vx-col w-full md:w-8/12 pr-10" }, [
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col pb-5 w-full" }, [
                _c("p", { staticClass: "font-bold pb-3 pr-3 w-full" }, [
                  _vm._v("\n          Nº Apólice\n\n          "),
                  _c(
                    "span",
                    {
                      staticClass: "font-bold text-sm text-grey-700 word-wrap",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.apolice.PolicyNumber || "-") +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-row pt-8" }, [
              _c("div", { staticClass: "vx-col w-full sm:w-1/2 md:w-5/12" }, [
                _c("p", { staticClass: "font-bold pb-3 w-full" }, [
                  _vm._v("Tomador"),
                ]),
                _c(
                  "p",
                  { staticClass: "font-bold w-full text-sm text-grey-700" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s((_vm.apolice.PolicyHolder || {}).Name || "-") +
                        "\n        "
                    ),
                  ]
                ),
                _c("p", { staticClass: "w-full text-xs" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$utils.maskDocument(
                          (_vm.apolice.PolicyHolder || {}).Document
                        ) || "-"
                      ) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "field-edit pt-8 pb-5",
                    attrs: { "vs-align": "flex-end" },
                  },
                  [
                    _c("p", { staticClass: "font-bold w-full" }, [
                      _vm._v("Endereço do Tomador"),
                    ]),
                    _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.policyHolderAddress || "-") +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "vx-col w-full sm:w-1/2 md:w-5/12" }, [
                _c("p", { staticClass: "font-bold pb-3 w-full" }, [
                  _vm._v("Segurado"),
                ]),
                _c(
                  "p",
                  { staticClass: "font-bold w-full text-sm text-grey-700" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s((_vm.apolice.Insured || {}).Name || "-") +
                        "\n        "
                    ),
                  ]
                ),
                _c("p", { staticClass: "w-full text-xs" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$utils.maskDocument(
                          (_vm.apolice.Insured || {}).Document
                        ) || "-"
                      ) +
                      "\n        "
                  ),
                ]),
                _c("div", { staticClass: "mb-0 field-edit pt-8 pb-5" }, [
                  _c("p", { staticClass: "font-bold w-full" }, [
                    _vm._v("Endereço do Segurado"),
                  ]),
                  _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.insuredAddress || "-") +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "vx-row mt-8" }, [
              _c("div", { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" }, [
                _c("p", { staticClass: "font-bold pt-8 pb-3 w-full" }, [
                  _vm._v("Modalidade Principal"),
                ]),
                _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s((_vm.apolice.Modality || {}).Name) +
                      "\n        "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" }, [
                _c("div", { staticClass: "pt-8 pb-5 field-edit" }, [
                  _c("p", { staticClass: "font-bold w-full" }, [
                    _vm._v("IS Principal"),
                  ]),
                  _c(
                    "p",
                    { staticClass: "font-bold w-full text-sm text-grey-700" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            (
                              _vm.apolice.InsuredAmountValue || 0
                            ).toLocaleString(
                              "pt-BR",
                              this.$utils.moneyFormatOptions
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" }, [
                _c("div", { staticClass: "pt-8 pb-5 field-edit" }, [
                  _c("p", { staticClass: "font-bold w-full" }, [
                    _vm._v("Vigência Principal"),
                  ]),
                  _vm.endosso.StartDate
                    ? _c(
                        "p",
                        {
                          staticClass: "w-full text-sm text-grey-700",
                          staticStyle: {
                            "border-bottom": "1px solid #ff8809",
                            "margin-bottom": "7px",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${_vm.$utils.dateToDDMMYYYY(
                                  _vm.endosso.StartDate
                                )} à ${_vm.$utils.dateToDDMMYYYY(
                                  _vm.apolice.EndDate
                                )}`
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              `${_vm.$utils.dateToDDMMYYYY(
                                _vm.apolice.StartDate
                              )} à ${_vm.$utils.dateToDDMMYYYY(
                                _vm.apolice.EndDate
                              )}`
                            ) +
                            "\n          "
                        ),
                      ]),
                ]),
              ]),
              _c("div", { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" }, [
                _c("div", { staticClass: "pt-8 pb-5 field-edit" }, [
                  _c("p", { staticClass: "font-bold w-full" }, [
                    _vm._v("Taxa aplicada (%)"),
                  ]),
                  _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s((_vm.apolice || {}).RiskRate) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm.apolice.ComplementaryModality &&
            _vm.apolice.ComplementaryModality.Id > 0
              ? _c("div", { staticClass: "vx-row mt-8" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" },
                    [
                      _c("p", { staticClass: "font-bold pt-8 pb-3 w-full" }, [
                        _vm._v("Modalidade Complementar"),
                      ]),
                      _c("p", { staticClass: "w-full text-sm text-grey-700" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.apolice.ComplementaryModality.Name) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" },
                    [
                      _c("div", { staticClass: "pt-8 pb-5 field-edit" }, [
                        _c("p", { staticClass: "font-bold w-full" }, [
                          _vm._v("IS Complementar"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-bold w-full text-sm text-grey-700",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (
                                    _vm.apolice
                                      .ComplementaryInsuredAmountValue || 0
                                  ).toLocaleString(
                                    "pt-BR",
                                    this.$utils.moneyFormatOptions
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" },
                    [
                      _c("div", { staticClass: "pt-8 pb-5 field-edit" }, [
                        _c("p", { staticClass: "font-bold w-full" }, [
                          _vm._v("Vigência Complementar"),
                        ]),
                        _c(
                          "p",
                          { staticClass: "w-full text-sm text-grey-700" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  `${_vm.$utils.dateToDDMMYYYY(
                                    _vm.apolice.ComplementaryModalityStartDate
                                  )} à ${_vm.$utils.dateToDDMMYYYY(
                                    _vm.apolice.ComplementaryModalityEndDate
                                  )}`
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full sm:w-1/2 md:w-3/12" },
                    [
                      _c("div", { staticClass: "pt-8 pb-5 field-edit" }, [
                        _c("p", { staticClass: "font-bold w-full" }, [
                          _vm._v("Taxa aplicada (%)"),
                        ]),
                        _c(
                          "p",
                          { staticClass: "w-full text-sm text-grey-700" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s((_vm.apolice || {}).RiskRate) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col pt-5" }, [
                _c("p", { staticClass: "font-bold pb-3 w-full" }, [
                  _vm._v("Objeto"),
                ]),
                _c("p", {
                  staticClass: "w-full text-sm object-justify",
                  domProps: { innerHTML: _vm._s(_vm.objeto || "-") },
                }),
              ]),
            ]),
          ]),
          _c(
            "aside",
            { staticClass: "vx-col w-full md:w-4/12 md:pl-24" },
            [
              _c("div", { staticClass: "vx-row" }, [
                _vm.apolice.Coverages && _vm.apolice.Coverages.length
                  ? _c(
                      "div",
                      { staticClass: "vx-col w-full pb-5" },
                      [
                        _c("p", { staticClass: "font-bold pt-8 pb-3 w-full" }, [
                          _vm._v("Coberturas adicionais"),
                        ]),
                        _vm._l(
                          _vm.apolice.Coverages,
                          function (coberturas, index) {
                            return _c(
                              "div",
                              {
                                key: `icon-${index}`,
                                staticClass: "w-full flex",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-sm text-grey-700 w-full",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "text-lg onpoint-check-circle text-primary pr-3",
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(coberturas.Name) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass: "vx-row",
                  class: {
                    "mt-8":
                      _vm.apolice.Coverages && _vm.apolice.Coverages.length,
                    "border-none":
                      !_vm.apolice.Coverages || !_vm.apolice.Coverages.length,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vx-col w-full",
                      class: {
                        "pt-8":
                          _vm.apolice.Coverages && _vm.apolice.Coverages.length,
                      },
                    },
                    [
                      _c("p", { staticClass: "font-bold pt-3 pb-3 w-full" }, [
                        _vm._v("Tipo do endosso"),
                      ]),
                      _c(
                        "p",
                        { staticClass: "w-full text-sm object-justify" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.tipoEndossoDescription) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "vs-row",
                { staticClass: "vx-row mt-5" },
                [
                  _c(
                    "vs-col",
                    {
                      staticClass: "container-element pt-3",
                      attrs: { "vs-w": "12" },
                    },
                    [
                      _c("p", { staticClass: "label-content label" }, [
                        _vm._v("Prêmio da apólice"),
                      ]),
                      _c("currency-input", {
                        staticClass:
                          "w-full vs-inputx vs-input--input large hasValue",
                        attrs: {
                          value: (_vm.apolice || {}).InsurancePremium,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.enableStartDateChange
                    ? _c(
                        "vs-col",
                        {
                          staticClass: "container-element pt-3 mt-4",
                          attrs: { "vs-w": "12" },
                        },
                        [
                          _c("p", { staticClass: "label-content label" }, [
                            _vm._v("Data do Cancelamento"),
                          ]),
                          _c("datepicker", {
                            staticClass: "w-full endosso-date",
                            attrs: {
                              language: _vm.ptBR,
                              format: "dd/MM/yyyy",
                              "disabled-dates": _vm.disabledDates,
                            },
                            model: {
                              value: _vm.newStartDate,
                              callback: function ($$v) {
                                _vm.newStartDate = $$v
                              },
                              expression: "newStartDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("revisao-premio", {
                attrs: {
                  loadingPremium: _vm.loadingPremium,
                  restituir: _vm.restituir,
                  IsBankInformationNeeded: _vm.IsBankInformationNeeded,
                },
                on: { updatePolicyHolderBankInfo: _vm.updateEndosso },
              }),
            ],
            1
          ),
          _c(
            "vs-popup",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dateLoading,
                  expression: "dateLoading",
                },
              ],
              staticClass: "date-new",
              attrs: {
                title: "Alterar Início de Vigência",
                active: _vm.modalStartDate,
              },
              on: {
                "update:active": function ($event) {
                  _vm.modalStartDate = $event
                },
              },
            },
            [
              _c(
                "vs-col",
                {
                  class: `container-element`,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { "vs-lg": "12", "vs-md": "12" },
                },
                [
                  _c("el-alert", {
                    attrs: {
                      title: "Atenção!",
                      type: "warning",
                      description:
                        "A alteração da data de início de vigência deve estar restrita a datas retroativas. Não será permitida a definição de uma data futura!",
                      "show-icon": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                {
                  class: `container-element`,
                  attrs: { "vs-lg": "4", "vs-md": "4" },
                },
                [
                  _c("p", { staticClass: "label-content label" }, [
                    _vm._v("Novo Início de Vigência"),
                  ]),
                  _c("datepicker", {
                    staticClass: "w-full",
                    staticStyle: { height: "50px" },
                    attrs: {
                      language: _vm.ptBR,
                      format: "dd/MM/yyyy",
                      "disabled-dates": _vm.disabledDates,
                    },
                    model: {
                      value: _vm.newStartDate,
                      callback: function ($$v) {
                        _vm.newStartDate = $$v
                      },
                      expression: "newStartDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-col",
                {
                  class: `container-element`,
                  staticStyle: { padding: "0px 5px" },
                  attrs: { "vs-lg": "4", "vs-md": "4" },
                },
                [
                  _c("p", { staticClass: "label-content label" }, [
                    _vm._v("Prazo em dias"),
                  ]),
                  _vm.deadlineDays
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          size: "large",
                          value: _vm.deadlineDays,
                          disabled: true,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "vs-col",
                {
                  class: `container-element`,
                  staticStyle: { padding: "0px 5px" },
                  attrs: { "vs-lg": "4", "vs-md": "4" },
                },
                [
                  _c("p", { staticClass: "label-content label" }, [
                    _vm._v("Fim de Vigência"),
                  ]),
                  _vm.endDate
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          size: "large",
                          value: _vm.$utils.dateToDDMMYYYY(_vm.endDate),
                          disabled: true,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "vs-col",
                {
                  class: `container-element`,
                  staticStyle: { padding: "15px 5px" },
                  attrs: { align: "right", "vs-lg": "12", "vs-md": "12" },
                },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "max-w-lg",
                      staticStyle: { "margin-right": "12px" },
                      attrs: {
                        color: "red",
                        type: "border",
                        disabled: !_vm.newStartDate,
                      },
                      on: {
                        click: function ($event) {
                          _vm.modalStartDate = false
                        },
                      },
                    },
                    [_vm._v("\n        Voltar\n      ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "max-w-lg",
                      attrs: {
                        color: "primary",
                        type: "border",
                        disabled: !_vm.newStartDate,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveDate()
                        },
                      },
                    },
                    [_vm._v("\n        Prosseguir\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }